import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.2/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.2/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["CookiePreferencesButton"] */ "/app/src/app_components/cookie/CookiePreferencesButton.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app_components/layout/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["FooterProfileLink"] */ "/app/src/app_components/layout/FooterProfileLink.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app_components/social/banner/ByLine.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Greeting"] */ "/app/src/app/Greeting.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/page.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app_components/social/banner/RoyalBanner.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app_components/generic/buttons/RoyalUrButton.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app_components/articles/Quote.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Welcome"] */ "/app/src/app/Welcome.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/res/ad/masters-ur-boards.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/res/ad/MTG-banner.png");
;
import(/* webpackMode: "eager" */ "/app/src/res/collision.png");
;
import(/* webpackMode: "eager" */ "/app/src/res/icons/discord.svg");
;
import(/* webpackMode: "eager" */ "/app/src/res/icons/facebook.svg");
;
import(/* webpackMode: "eager" */ "/app/src/res/icons/instagram.svg");
;
import(/* webpackMode: "eager" */ "/app/src/res/icons/reddit.svg");
;
import(/* webpackMode: "eager" */ "/app/src/res/icons/twitter.svg");
;
import(/* webpackMode: "eager" */ "/app/src/res/logo_text_only.svg");
;
import(/* webpackMode: "eager" */ "/app/src/res/logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/res/photos/veulatonra_board_scaled.png");
